const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_ERROR = "LOGIN_ERROR";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const SET_LOGIN = "SET_LOGIN";

export {
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGIN_REQUEST,
    LOGOUT,
    SET_LOGIN
}