import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    SET_LOGIN
} from "./login.state";

const Model = {
    isLoading:false,
    error:null,
    isLogin:localStorage.getItem('icp_islogin') ? true : false,
    isLogout:false,
    data:[]
}
const loginReducer = (state=Model,action)=>{

    switch(action.type)
    {
        case LOGIN_REQUEST : return {
            ...state,
            isLoading:true,
            isLogin:false,
            isLogout:false

        }

        case LOGIN_SUCCESS : return {
            ...state,
            isLoading:false,
            isLogin:true,
            isLogout:false,
            data:action.payload
        }
        case LOGIN_ERROR : return {
            ...state,
            isLoading:false,
            isLogin:false,
            isLogout:false,
            error:action.error,
            data:action.error
        }

        case SET_LOGIN : return {
            ...state,
            isLoading:false,
            isLogin:true,
            isLogout:false,
            data:action.payload
        }

        case LOGOUT : return {
            ...state,
            isLoading:false,
            isLogin:false,
            isLogout:true,
            data:{"logout":true}
        }

        default : return state;
    }

}

export default loginReducer;