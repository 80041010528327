import React, { Suspense, useEffect, useState } from 'react';
import storage from './storage.js';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index/index';
import Navbar from './component/Navbar/navbar';
import './assets/css/tailwind.css';
import './assets/css/custom.css';
import './index.css';
import  Cookies  from 'universal-cookie';
import { isOtpVerify } from './component/RouteCheck/RouteCheck';
import { useSelector, useDispatch } from 'react-redux';
import { webRequest } from './pages/website/website_page.action';
import { navbarFetch } from './component/Navbar/FetchPage/Navbar.action';
import Loader from './pages/Loader/Loader';
const PaymentMethods = React.lazy(()=>import('./pages/home/Payment/PaymentMethods.jsx'))

const Login = React.lazy(() => import('./pages/index/Login/login'));
const About = React.lazy(() => import('./pages/index/about'));
const CourseListing =  React.lazy(() => import('./pages/index/course_listing'));

// Industries pages //
const Signup = React.lazy(() => import('./pages/index/signup'));
const Checkout = React.lazy(() => import('./pages/index/checkout'));
const CourseDetails = React.lazy(() => import('./pages/index/course_details'));
const Shopaccount = React.lazy(() => import('./pages/index/shop_account'));
const ContactTwo = React.lazy(() => import('./pages/contact/contactTwo'));
const ContactOne = React.lazy(() => import('./pages/contact/contactOne'));
const ContactDetail = React.lazy(() => import('./pages/contact/contactDetail'));
const Blog = React.lazy(() => import('./pages/blog/blog'));
const GetStarted = React.lazy(() => import('./pages/index/get-started'));
const Gallery = React.lazy(() => import('./pages/index/Gallery/Gallery'));

const Switch = React.lazy(() => import('./component/Switch'));

const UnderConstruction = React.lazy(() => import('./pages/website/website_page'));


const Otpverify = React.lazy(() => import('./pages/Otpverify/Otpverify'));
const ForgotOtpverify = React.lazy(() => import('./pages/Otpverify/Forgot_Otpverify'));
const Forgot = React.lazy(() => import('./pages/Forgot/Forgot'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword/ResetPassword'));
//const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'));
const BlogDetails = React.lazy(() => import('./pages/blog/blogDetail'));
const DynamicPage = React.lazy(() => import('./pages/DynamicPage/DynamicPage'));
const FreeResource = React.lazy(()=> import('./pages/FreeResource/FreeResource'));

const AppRoutes = () => {
    const dispatch = useDispatch();
    const response = useSelector((state) => state.websiteReducer);
    const isLogin = useSelector((state) => state.loginReducer.isLogin);
    const isLogout = useSelector((state) => state.loginReducer.isLogout);
    const [siteStatus, setSiteStatus] = useState(null);
    const cookie = new Cookies();
    const [isReset, setIsReset ] = useState(false);


    useEffect(() => {

        dispatch(webRequest());
        dispatch(navbarFetch("about-us"));
        if(cookie.get("icp_forgot_otp"))
        {
            setIsReset(true);
        }
        console.log(isReset);

    }, [dispatch]);

    useEffect(() => {
        if (response && response.data && response.data.data) {
            setSiteStatus(response.data.data.site_status);
            const siteData = JSON.stringify({
                favicon: response.data.data.site_favicon,
                site_logo: response.data.data.site_logo,
                title: response.data.data.site_name
            });
            cookie.set('icp_favicon',siteData);

            const headerData = JSON.stringify({
                foot_tag: response.data.data.site_meta_foot_tag,
                head_tag: response.data.data.site_meta_head_tag
            });
            localStorage.setItem("icp_header_data",headerData);

        }



    }, [response]);


    if (siteStatus === null) {
        return <Loader />;
    }

    if (siteStatus === 0) {
        return (

            <Routes>
                <Route path="*" element={<Navigate to="/under-construction" />} />
                <Route path="/under-construction" element={<UnderConstruction />} />
            </Routes>

        );
    }

    return (

        <Routes>
            <Route path="/under-construction" element={<Navigate to="/index" />} />
            <Route path="/" element={<Navigate to="/index" />} />
            <Route exact path="/index" element={<Index />} />
            <Route exact path="/navbar" element={<Navbar />} />
            <Route exact path="/get-started" element={<GetStarted />} />
            <Route exact path="/page/:slug" element={<DynamicPage />} />

            <Route exact path="/verify-otp" element={<Otpverify />} />


            <Route exact path="/forgot-otp-verify" element={<ForgotOtpverify /> } />

            <Route exact path='/profile' element={isLogin ? <Shopaccount /> : <Navigate to="/index" />} />
            <Route exact path='/forgot-password' element={<Forgot />} />

            <Route exact path='/password-reset' element={ <ResetPassword />} />

            <Route exact path="gallery" element={<Gallery />} />
            <Route exact path="/about" element={<About />} />
            <Route path="/category/:cat_id" element={<CourseListing />} />
            <Route path="/category" element={<CourseListing />} />


            <Route exact path="/login" element={isLogin === false  ? <Login /> : <Navigate to="/index" />} />
            <Route exact path="/signup" element={isLogin === false ? <Signup /> : <Navigate to="/index" />} />
            <Route exact path="/checkout" element={ isLogin === true ?<Checkout /> : <Navigate to="/signup" />} />

            <Route exact path="/payment-methods" element={ isLogin === true ?<PaymentMethods /> : <Navigate to="/index" />} />


            <Route exact path="/course" element={<CourseDetails />} />
            <Route exact path="/course/:p_id" element={<CourseDetails />} />
            <Route exact path="/contact-two" element={<ContactTwo />} />
            <Route exact path="/contact-one" element={<ContactOne />} />
            <Route exact path="/contact-detail" element={<ContactDetail />} />
            <Route exact path="/contact-detail/:id" element={<ContactDetail />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog-details/:slug" element={<BlogDetails />} />
            <Route path="*" element={<Navigate to="/index" />} />
            <Route exact path="/page/free-resources" element={<FreeResource />} />
        </Routes>

    );
};

export default function App() {
    return (
        <Provider store={storage}>
            <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Switch />
                <AppRoutes />
                </Suspense>
            </BrowserRouter>
        </Provider>
    );
}
